import { BaseResource } from '../index'
import { DocumentPage } from './page.js'
import { humanFileSize } from '@/util/docs.js'

export class DocumentResource extends BaseResource {
    static endpoint = '/docs'

    static related() {
        return {
            employee: require('@/resources/employee.js').EmployeeResource
        }
    }

    static async upload(ownerId, userFileData, employeeId = null) {
        const formData = new FormData()
        formData.append('media', userFileData)
        formData.append('owner', ownerId)

        if (employeeId) {
            formData.append('employee', employeeId)
        }

        const result = await this.wrap('/').post(formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return new DocumentResource(result.data)
    }

    constructor(...args) {
        super(...args)
        this.pages = this.getDocumentPages()
    }

    async parseDocument() {
        const result = await this.wrap('/parse').post()
        
        for (let key in result.data) {
            this.set(key, result.data[key])
        }

        this.pages = this.getDocumentPages()

        return this
    }

    async getURL() {
        const result = await this.wrap('/view').get()
        return result.data.url
    }

    getDocumentPages() {
        if (Object.keys(this.at.data)) {
            let pages = []

            for(let pageDataKey in this.at.data) {
                const pageData = this.at.data[pageDataKey]
                pages.push(new DocumentPage(pageData))
            }

            return pages
        }

        return []
    }

    get humanFileSize() {
        return humanFileSize(this.at.size)
    }
}

export class DocumentTypeResource extends BaseResource {
    static endpoint = '/docs/types'
    static cacheMaxAge = Infinity

    static related() {
        return {
            groups: DocumentTypeGroupResource
        }
    }
}

export class DocumentTypeGroupResource extends BaseResource {
    static endpoint = '/docs/types/groups'
    static cacheMaxAge = Infinity
}
