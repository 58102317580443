import Messages from '../components/Messages.vue'
import { snakeCaseToTitleCase } from '../util/string'

export default {
    data() {
        return {
            messages: [],
        }
    },
    components: {
        Messages,
    },
    methods: {
        addMessage(variant, text, timeoutMs = 5000) {
            // let msgObj = { variant, text }
            // this.messages.push(msgObj)

            // setTimeout(() => {
            //     let msgIndex = this.messages.indexOf(msgObj)
            //     this.messages.splice(msgIndex, 1)
            // }, timeoutMs)

            this.$root.$bvToast.toast(text, {
                variant,
                solid: true,
                autoHideDelay: timeoutMs
            })
        },
        addError(text, timeoutMs = 5000) {
            this.addMessage('danger', text, timeoutMs)
        },
        addWarning(text, timeoutMs = 5000) {
            this.addMessage('warning', text, timeoutMs)
        },
        addSuccess(text, timeoutMs = 5000) {
            this.addMessage('success', text, timeoutMs)
        },
        getResponseMessageFromAxiosException(exception, codeMessages = {}) {
            const statusCode = exception?.response?.status
            const data = exception?.response?.data || {}

            if (Object.keys(codeMessages).length > 0 && codeMessages[statusCode]) {
                return codeMessages[statusCode]
            } else if (Object.keys(data).length > 0 && statusCode > 399 && statusCode < 500) {
                let errString = ''

                if (data['non_field_errors']) {
                    errString += data['non_field_errors'].join('. ')
                }

                for (let key in data) {
                    if (key === 'non_field_errors') {
                        continue
                    }
                    
                    errString += `${snakeCaseToTitleCase(key)}: ${data[key]}`
                }

                return errString
            } else if (exception.message) {
                return exception.message
            }

            return String(exception)
        },
        extractResponseMessageFromAxiosException(exc, codeMessages = {}) {
            if (Array.isArray(exc)) {
                exc.forEach((excc) => {
                    this.addMessage('danger', this.getResponseMessageFromAxiosException(excc, codeMessages))
                })
            } else {
                this.addMessage('danger', this.getResponseMessageFromAxiosException(exc, codeMessages))
            }

            this.$emit('error', exc)
            return exc
        },
        axiosExtract(exc, codeMessages = {}) {
            return this.extractResponseMessageFromAxiosException(exc, codeMessages)
        },
    },
}
