<script>
export default {
    props: {
        value: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            messages: this.value || this.$parent.messages
        }
    }
}
</script>
<template>
    <span v-if="messages.length > 0">
        <b-alert :variant="message.variant" show v-for="(message, i) in messages" :key="i" v-html="message.text"></b-alert>
    </span>
</template>
