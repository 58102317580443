import Vue from 'vue'
import App from './App.vue'
import BoostrapVue, { BootstrapVueIcons } from 'bootstrap-vue'
import Router from 'vue-router'
import router from '@/router.js'
import store from '@/store.js'
import messagesMixin from './mixins/messages.js'
import '@/assets/main.scss'

Vue.config.productionTip = false

Vue.use(BoostrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Router)

Vue.mixin({
    components: {},
    methods: {},
    mixins: [
        messagesMixin
    ]
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
