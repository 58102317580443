import Resource from 'rest-resource'
import { range, flatten } from 'lodash'
import { client } from './client'

export class BaseResource extends Resource {

    constructor(...args) {
        super(...args)
        Object.assign(this._attributes, this._attributes.computed_values)
    }

    static get client() {
        return client
    }

    static async all(options = {}) {
        let content = await this.list(options)
        let otherPages = content.otherPageNumbers()

        let allResources = {
            [content.page]: content.resources,
        }

        let promises = otherPages.map((page) => {
            let thisPageQuery = Object.assign({}, content.query, { page })
            return this.list(Object.assign({}, options, { query: thisPageQuery })).then((res) => {
                allResources[page] = res.resources
            })
        })

        await Promise.all(promises)
        return flatten(Object.values(allResources))
    }

    static filter(filterArgs) {
        return this.list({ query: filterArgs })
    }

    static filterAll(filterArgs, options = {}) {
        let opts = Object.assign({}, { query: filterArgs }, options)
        return this.all(opts)
    }

    static search(searchString, filterArgs = {}) {
        let query = Object.assign(filterArgs, { search: searchString })
        return this.list({ query })
    }

    static searchAll(searchString, filterArgs = {}) {
        let query = Object.assign(filterArgs, { search: searchString })
        return this.all({ query })
    }

    get at() {
        return this.attributes
    }

    setMany(obj) {
        for (let key in obj) {
            this.set(key, obj[key])
        }

        return this
    }


    toString() {
        if (this.attributes.name) {
            return this.attributes.name
        }

        return super.toString()
    }
}
