export function randomStringGenerate(stringLength = 20) {
    return (Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2)).substring(0, stringLength)
}

export function snakeCaseToTitleCase(s) {
    return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))
}

export function emailValid(value) {
    const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regExp.test(value)
}

export function strMaxLength(value, maxLength) {
    if (value.length <= maxLength) {
        return value
    }

    return value.slice(0, maxLength - 1) + '...'
}
