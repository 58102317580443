import { BaseResource } from './index'

export class TrxResource extends BaseResource {
    static endpoint = '/trxs'

    static related() {
        return {
            lines: TrxLineResource
        }
    }

    static defaults() {
        return {
            status: 'Open',
            lines: [],
            notes: ''
        }
    }

    toString() {
        return 'Transaction #' + this.at.id
    }

    async post() {
        const result = await this.wrap('/post').post()
        this.setMany(result.data)
        return this
    }

    async audit() {
        const result = await this.wrap('/audit').post()
        this.setMany(result.data)
        return this
    }

    async void() {
        const result = await this.wrap('/void').post()
        this.setMany(result.data)
        return this
    }
}

export class TrxLineResource extends BaseResource {
    static endpoint = '/trxs/lines'

    static related() {
        return {
            trx: TrxResource
        }
    }

    static defaults = {
        account: 'EXPENSES',
        amount_cents: 0
    }
}

export class TrxAccountResource extends BaseResource {
    static endpoint = '/trxs/accounts'
    static cacheMaxAge = Infinity
}
