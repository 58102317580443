<script>
import { mapGetters } from 'vuex'

export default {
    name: 'App',
    components: {},
    computed: {
        ...mapGetters(['appInitializing'])
    },
    data() {
        return {
            redirecting: false
        }
    },
    async beforeMount() {
        try {
            await this.$store.dispatch('appInitialize')
        } catch (e) {
            if (e.response?.status == 401 && !this.redirecting) {
                this.redirecting = true
                this.$router.push({ name: 'login', query: { code: 'sessionInvalid' } })
            }
        } finally {
            this.$store.commit('setInitializing', false)
        }
    },
}
</script>

<template>
    <div id="app">
        <div v-if="appInitializing" class="app-loading">
            <b-spinner type="grow" variant="secondary" label="Spinning"></b-spinner>
        </div>
        
        <div v-else>
            <router-view name="nav" />
            <router-view name="body" />
        </div>
    </div>
</template>

<style lang="scss">
#app {
    margin: 0;
    padding: 0 0 20px 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f3f3;

    .app-loading {
        height: 100%;
        min-height: 100%;
        line-height: 500px;
        text-align: center;
    }
}
</style>
