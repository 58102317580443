import Vue from 'vue'
import Vuex from 'vuex'
import * as config from '@/config'
import { client } from '@/resources/client'
import { UserResource } from '@/resources/user'
import { CompanyResource } from '@/resources/company'
import { DocumentTypeResource, DocumentTypeGroupResource } from '@/resources/document'
import { TrxAccountResource } from '@/resources/transaction'
import { getTokenPayload } from '@/util/token'

Vue.use(Vuex)

export const localToken = localStorage.getItem('token') || null
export const localCompanyId = localStorage.getItem('companyId') || null

if (localToken) {
    client.token = localToken
}

export default new Vuex.Store({
    state: {
        config,
        appInitializing: true,
        userResource: null,
        companyResource: null,
        companyResources: [],
        globalResources: {},
        token: localToken,
    },
    getters: {
        token(state) {
            return state.token
        },
        tokenPayload(state) {
            return getTokenPayload(state.token)
        },
        userId(state) {
            let payload = getTokenPayload(state.token)

            if (payload) {
                return payload['user_id']
            }

            return undefined
        },
        userResource(state) {
            return state.userResource
        },
        companyResource(state) {
            return state.companyResource
        },
        companyResources(state) {
            return state.companyResources
        },
        globalResources(state) {
            return state.globalResources
        },
        config(state) {
            return state.config
        },
        appInitializing(state) {
            return state.appInitializing
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            client.token = token
            localStorage.setItem('token', token)
        },
        setLoggedOut(state) {
            state.token = null
            state.userResource = null
            state.companyResource = null
            state.companyResources = []
            localStorage.clear()
        },
        setUserResource(state, userResource) {
            state.userResource = userResource
        },
        setCompanyResource(state, companyResource) {
            state.companyResource = companyResource

            if (companyResource) {
                localStorage.setItem('companyId', companyResource.id)
            } else {
                localStorage.removeItem('companyId')
            }
        },
        setCompanyResources(state, companyResources) {
            state.companyResources = companyResources
        },
        setGlobalResources(state, globalResources) {
            state.globalResources = globalResources
        },
        setInitializing(state, initializingState) {
            state.appInitializing = initializingState
        }
    },
    actions: {
        async login(context, creds) {
            const result = await UserResource.login(creds.username, creds.password)
            context.commit('setToken', result.token)
            context.commit('setUserResource', result.userResource)
            return result
        },
        async appInitialize(context) {
            context.commit('setInitializing', true)

            const initializingResult = await Promise.all([
                DocumentTypeResource.all(),
                DocumentTypeGroupResource.all(),
                TrxAccountResource.all(),
                context.dispatch('getUserResource'),
                context.dispatch('getCompanyResources')
            ])
            
            context.commit('setGlobalResources', {
                documentTypeResources: initializingResult[0],
                documentTypeGroupResources: initializingResult[1],
                trxAccountResources: initializingResult[2],
            })

            context.commit('setInitializing', false)
            return initializingResult
        },
        async getUserResource(context) {
            if (!context.getters.userId) {
                return null
            }

            const user = await UserResource.detail(this.getters.userId)
            context.commit('setUserResource', user)
            return user
        },
        async getCompanyResources(context) {
            if (!context.getters.userId) {
                return []
            }

            const companies = await CompanyResource.all()
            context.commit('setCompanyResources', companies)

            if (localCompanyId && companies.length > 0) {
                const localCompany = companies.find((companyResource) => companyResource.id === localCompanyId)
                context.commit('setCompanyResource', localCompany)
            } else if (!context.getters.companyResource && companies.length > 0) {
                context.commit('setCompanyResource', companies[0])
            }

            return companies
        },
    },
    modules: {},
})
