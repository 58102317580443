import { URL } from 'url-shim'
import { range } from 'lodash'
import assert from 'assert'

export class DefaultContentNegotiator {
    resources = []

    constructor(ResourceClass, response) {
        this.ResourceClass = ResourceClass
        this.response = response
        this.body = this.response.data
        this.urlInfo = new URL(response.request.responseURL)

        if (this.urlInfo.search.length > 0) {
            this.query = Object.fromEntries(this.urlInfo.searchParams)
        } else {
            this.query = {}
        }

        this.parseResources()
    }

    parseResources() {
        const ResourceClass = this.ResourceClass
        this.resources.push(new ResourceClass(this.body))
    }
}

export class PagedContentNegotiator extends DefaultContentNegotiator {
    constructor(ResourceClass, response) {
        super(ResourceClass, response)
        assert(this.body.results, 'Can\'t create PageResponse instance without "results" property in body')
        this.count = Number(this.body.count)
        this.pages = Number(this.body.page_count)
        this.pageSize = Number(this.body.page_size)
        this.page = Number(this.query.page) || 1
        this.hasNextPage = this.pages > this.page
        this.hasPrevPage = this.page <= this.pages && this.page > 1
    }

    static isListResponse(response) {
        return response.data && response.data.results
    }

    assertPageSize(page) {
        assert(page >= 1 && page <= this.pages, `Page size must be between 1 and ${this.pages} (received ${page})`)
    }

    parseResources() {
        const ResourceClass = this.ResourceClass
        for (let i = 0; i < this.body.results.length; i++) {
            this.resources.push(new ResourceClass(this.body.results[i]))
        }
    }

    nextPage(listOptions = null) {
        const ResourceClass = this.ResourceClass
        const query = Object.assign({}, this.query, { page: this.page + 1 })
        this.assertPageSize(query.page)
        const opts = Object.assign({ query }, listOptions)
        return ResourceClass.list(opts)
    }

    prevPage(listOptions = null) {
        const ResourceClass = this.ResourceClass
        const query = Object.assign({}, this.query, { page: this.page - 1 })
        this.assertPageSize(query.page)
        const opts = Object.assign({ query }, listOptions)
        return ResourceClass.list(opts)
    }

    otherPageNumbers() {
        return range(1, this.pages + 1, 1).filter((page) => page != this.page)
    }
}
