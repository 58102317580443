import { BaseResource } from './index'
import { client } from '@/resources/client/index.js'
import { EmployeeJurisReqResource } from '@/resources/employeeJurs.js'

export class EmployeeResource extends BaseResource {
    static endpoint = '/ees'

    static related() {
        return {
            owner: require('@/resources/company.js').CompanyResource,
        }
    }

    async createJurisdictions() {
        const result = await this.wrap('/jurs').post()
        const conneg = client.negotiateContent(EmployeeJurisReqResource)
        return conneg(result)
    }

    static async generatePayrollData(payrollData) {
        const result = await this.wrap('/payroll').post(payrollData)
        return result.data
    }

    get cityAndState() {
        return [
            this.at.city,
            this.at.state
        ].filter((item) => !!item).join(', ')
    }

    toString() {
        return this.at.full_name
    }
}
