<script>
import { mapGetters } from 'vuex'

export default {
    components: {},
    computed: {
        ...mapGetters(['userResource', 'companyResource'])
    },
    watch: {},
    data() {
        return {}
    },
    methods: {},
}
</script>

<template>
    <b-navbar toggleable="lg" class="nav-main">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-brand :to="{ name: 'home' }">
            <!-- <b-img width="120" :src="require('@/assets/logo.png')" /> -->
            Hire<strong>IQ</strong>
        </b-navbar-brand>
        <small class="text-muted">HR Intelligence API Demo — <a href="mailto:info@narrowlabs.com">Contact Sales</a></small>
        <b-collapse is-nav id="nav-collapse">

            <b-navbar-nav class="ml-auto" v-if="userResource">
                <b-nav-item :to="{ name: 'logout' }">Logout</b-nav-item>
                <b-nav-item :to="{ name: 'trxs' }">Transactions</b-nav-item>
                <b-nav-item :to="{ name: 'payroll' }">Payroll</b-nav-item>
                <b-nav-item :to="{ name: 'employees' }">Employees</b-nav-item>
                <b-nav-item :to="{ name: 'documents' }">Documents</b-nav-item>
                <b-nav-item :to="{ name: 'documentsUpload' }">
                    <b-button variant="primary" size="sm">Upload</b-button>
                </b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto" v-else>
                <!-- logged out -->
            </b-navbar-nav>

        </b-collapse>
    </b-navbar>
</template>

<style lang="scss" scoped>
.nav-main {
    background-color: #fff;
    height: 62px;

    &:after {
        background: linear-gradient(to right, #6200ea, #249afb);
        content: "";
        width: 100%;
        top: 61px;
        position: absolute;
        height: 3px;
        left: 0;
    }

    .navbar-brand {
        color: #6200ea;
    }

    .nav-item {
        a {
            line-height: 28px;
        }
    }
}
</style>
