import axios from 'axios'
import router from '@/router'
import { BaseClient } from 'rest-resource/dist/client'
import { DefaultContentNegotiator, PagedContentNegotiator } from './conneg'
import { getTokenPayload } from '../../util/token'

export class DefaultClient extends BaseClient {
    constructor(baseURL, token, config) {
        super(baseURL, config)

        if (!this.config.headers) {
            this.config.headers = {}
        }

        if (token) {
            this.token = token
        }
    }

    negotiateContent(ResourceClass) {
        return (response) => {
            if (PagedContentNegotiator.isListResponse(response)) {
                return new PagedContentNegotiator(ResourceClass, response)
            } else {
                return new DefaultContentNegotiator(ResourceClass, response)
            }
        }
    }

    get token() {
        return this._token
    }

    set token(value) {
        if (!this.config.headers) {
            this.config.headers = {}
        }

        this._token = value

        if (this._token) {
            this.config.headers['Authorization'] = `Bearer ${value}`
        }

        this.axios = axios.create(this.config)
    }

    getTokenPayload() {
        return getTokenPayload(this.token)
    }

    tokenIsExpired() {
        try {
            let payload = this.getTokenPayload()
            let nowInSeconds = Math.floor(Date.now() / 1000)
            return payload.exp < nowInSeconds
        } catch (e) {
            return true
        }
    }

    tokenIsValid() {
        return this.token && !this.tokenIsExpired()
    }

    tokenBecomesExpired() {
        router.push({ name: 'login', query: { errorCode: 'tokenExpired' } })
    }

    onError(e) {
        if (e.response?.data?.code == 'token_not_valid') {
            this.tokenBecomesExpired()
        }

        throw e
    }
}
