import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Nav from '@/components/Nav.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        beforeEnter(to, from, next) {
            next({ name: 'documentsUpload' })
        },
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/login',
        name: 'login',
        components: {
            nav: Nav,
            body: () => import('@/views/Login.vue'),
        },
        beforeEnter(to, from, next) {
            store.commit('setLoggedOut')
            next()
        }
    },
    {
        path: '/logout',
        name: 'logout',
        beforeEnter(to, from, next) {
            next({ name: 'login' })
        }
    },
    {
        path: '/docs',
        components: {
            nav: Nav,
            body: () => import('@/views/Docs.vue'),
        },
        children: [{
            path: '',
            name: 'documents',
            components: {
                main: () => import('@/views/DocsList.vue'),
            },
            meta: {
                loginRequired: true
            },
        }, {
            path: 'upload',
            name: 'documentsUpload',
            components: {
                main: () => import('@/views/DocsUpload.vue'),
            },
            meta: {
                loginRequired: true
            },
        }, {
            path: ':id',
            name: 'documentsDetail',
            components: {
                main: () => import('@/views/DocsDetail.vue'),
            },
            meta: {
                loginRequired: true
            },
        }]
    },
    {
        path: '/ees',
        components: {
            nav: Nav,
            body: () => import('@/views/Employees.vue'),
        },
        children: [{
            path: '',
            name: 'employees',
            components: {
                main: () => import('@/views/EmployeesList.vue'),
            },
            meta: {
                loginRequired: true
            },
        }, {
            path: ':id',
            name: 'employeesDetail',
            components: {
                main: () => import('@/views/EmployeesDetail.vue'),
            },
            meta: {
                loginRequired: true
            },
        }]
    },
    {
        path: '/payroll',
        name: 'payroll',
        components: {
            nav: Nav,
            body: () => import('@/views/Payroll.vue'), 
        },
        meta: {
            loginRequired: true
        },
    },
    {
        path: '/trxs',
        components: {
            nav: Nav,
            body: () => import('@/views/Trxs.vue'),
        },
        children: [{
            path: '',
            name: 'trxs',
            components: {
                main: () => import('@/views/TrxsList.vue'),
            },
            meta: {
                loginRequired: true
            },
        }, {
            path: ':id',
            name: 'trxsDetail',
            components: {
                main: () => import('@/views/TrxsDetail.vue'),
            },
            meta: {
                loginRequired: true
            },
        }],
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    const payload = store.getters.tokenPayload

    if (payload && payload.exp <= Math.round(Date.now() / 1000) && to.name !== 'login') {
        return next({ name: 'login', query: { code: 'tokenExpired', redirect: to.fullPath } })
    }

    if (!store.getters.token && to.meta.loginRequired) {
        return next({ name: 'login', query: { redirect: to.fullPath } })
    }

    document.title = to.meta.title ? `HireIQ | ${to.meta.title}` : 'HireIQ - HR Intelligence'

    next()
})

export default router
