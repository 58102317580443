// Config
export const IS_WINDOW = 'object' === typeof window
// Environment
export const ENV = (IS_WINDOW && window.location.href.match(/localhost|ngrok|develop/gi)) ? 'dev': 'prod'
export const IS_DEV = ENV == 'dev'
export const IS_STAGING = IS_DEV && window.location.href.match(/^https?:\/\/develop/i)
// Don't use a trailing slash for API_BASE_URLs
export const API_BASE_URL_DEV = 'http://localhost:8000'
export const API_BASE_URL_PROD = 'https://api.hireiq.narrowlabs.com'
// SSL
export const USE_HTTPS = IS_STAGING || !IS_DEV
// Base URL
export const API_BASE_URL = ENV === 'dev' ? API_BASE_URL_DEV : API_BASE_URL_PROD
