import store from '@/store'

export class DocumentPage {
    constructor(attrs) {
        this.keys = Object.keys(this)
        this.items = attrs
        this.type = this.getDocumentTypeResource()
    }

    get typeId() {
        return this.items?.type || null
    }

    getDocumentTypeResource() {
        if (!this.typeId) {
            return null
        }

        const { documentTypeResources } = store.state.globalResources
        return documentTypeResources.find((resource) => resource.id === this.typeId)
    }

    getDocumentTypeGroupIds() {
        const docTypeResource = this.getDocumentTypeResource()
        return docTypeResource?.at.groups || []
    }

    mergeWithResource(resourceInstance, otherKeysMap = {}) {
        for (let key in resourceInstance.attributes) {
            if (this.keys.includes(key)) {
                resourceInstance.attributes[key] = this.items[key]
            }
        }

        for (let otherKey in otherKeysMap) {
            resourceInstance.attributes[otherKey] = this.items[otherKey]
        }

        return resourceInstance
    }

    removeEmpties() {
        return Object.fromEntries(Object.entries(this.items).filter(([_, v]) => v != null))
    }
}
