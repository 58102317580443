import { BaseResource } from './index'

export class UserResource extends BaseResource {
    static endpoint = '/users'

    static async login(username, password) {
        const result = await UserResource.client.post('/auth/token', { username, password })
        BaseResource.client.token = result.data.access
        const payload = BaseResource.client.getTokenPayload()
        const userResource = await UserResource.detail(payload['user_id'])
        return {
            userResource,
            token: result.data.access,
        }
    }
}
