import { Buffer } from 'buffer'

export function getTokenPayload(token) {
    try {
        let jwtPieces = token.split('.')
        let payloadBase64 = jwtPieces[1]
        let payloadBuffer = Buffer.from(payloadBase64, 'base64').toString()
        return JSON.parse(payloadBuffer.toString())
    } catch (e) {
        return undefined
    }
}
